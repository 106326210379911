import React, { useState } from 'react'

import QRCode from 'qrcode.react';
import Icono from "../ClasesFunsiones/Iconos"

import Consultas from "../ClasesFunsiones/consultas"

function Share(props){
    const [TipoShare, setTipoShare] = useState(0)
    let comp
    switch (TipoShare) {
        case 1:
            comp = <Mail {...props}/>
            break;
        case 2:
            comp = <QR {...props}/>
                break;
        default:
            comp = <WP {...props}/>
            break;
    }
    let cambiarshare = (e)=>{
        setTipoShare(e)
    }
    return(
        <div className="Share">
            {comp}
            <div className="Selector">
                <BtnShare icon="WP" onClick={()=>{cambiarshare(0)}}/>
                <BtnShare icon="mail" onClick={()=>{cambiarshare(1)}}/>
                <BtnShare icon="QR" onClick={()=>{cambiarshare(2)}}/>
            </div>
        </div>
    )
}

const BtnShare = (props)=>{
    return(
        <div className="BtnShare" onClick={props.onClick}>
            <div className="iconcont">
                <Icono icon={props.icon} color="white"/>
            </div>
        </div>
    )
}

function tiempoinicio(){
  return JSON.parse(window.localStorage.getItem("ConfiguracionGeneral")).sharetime
}

const WP = (props)=>{
    const [WPenvio, setWPenvio] = useState({prefijo:"58",numero:"",tiempo:tiempoinicio(),enviando:false})
    let cambiarprefijo = (e)=>{
        setWPenvio({...WPenvio,prefijo:e.target.value})
    }
    let cambiarnumero = (e)=>{
        setWPenvio({...WPenvio,numero:e.target.value})
    }
    let cambiartiempo = (e)=>{
        setWPenvio({...WPenvio,tiempo:e.target.value})
    }
    let shareWP = ()=>{
        setWPenvio({...WPenvio,enviando:true})
        props.paciente.ShareWP(WPenvio)
        setTimeout(() => {
            setWPenvio({...WPenvio,enviando:false})
        }, 2000);
    }
    return (
      <div className="contenedor">
        <div className="inputdata">
          <div className="contenedorinput">
            <select name="" className="inputitem" value={WPenvio.prefijo} onChange={cambiarprefijo}>
              <option value="591">Bolivia</option>
              <option value="1">Estados Unidos</option>
              <option value="595">Paraguay</option>
              <option value="58">Venezuela</option>
            </select>
          </div>
          <div className="contenedorinput">
            <input type="tel" className="inputitem" placeholder="Numero Telefonico" value={WPenvio.numero} onChange={cambiarnumero}/>
          </div>
          <div className="contenedorinput">
            <span className="Subtitulo">Tiempo de vida:</span>
            <input type="number" className="inputitem2" placeholder="tiempo en horas" value={WPenvio.tiempo} onChange={cambiartiempo}/>
            <span className="Subtitulo">Horas</span>
          </div>
          <div className="contenedorinput">
            <button disabled={WPenvio.enviando} className="Boton" onClick={shareWP}>enviar</button>
          </div>
        </div>
        <div className="Lista">
          <ul>
            
          </ul>
        </div>
      </div>
    );
}

const Mail = (props)=>{
    const [Mailenvio, setMailenvio] = useState({direccion:"",tiempo:48,enviando:false})
    let cambiardirecion = (e)=>{
        setMailenvio({...Mailenvio,direccion:e.target.value})
    }
    let cambiartiempo = (e)=>{
        setMailenvio({...Mailenvio,tiempo:e.target.value})
    }
    let shareMail = ()=>{
        setMailenvio({...Mailenvio,enviando:true})
        props.paciente.ShareMail(Mailenvio)
        .then((res)=>{
          Consultas.sharecorreo(res)
          .then((res)=>{
            //console.log(res)
            if(res.res){
              alert("Correo enviado")
              setMailenvio({...Mailenvio,enviando:false})
            }else{
              alert("Envio Fallido")
              setMailenvio({...Mailenvio,enviando:false})
            }
          })
        })
        .catch((e)=>{
          alert("Correo invalido")
          setMailenvio({...Mailenvio,enviando:false})
          //console.log(e)
        })
    }
    return (
      <div className="contenedor">
        <div className="inputdata">
          <div className="contenedorinput">
            <input type="mail" className="inputitem" placeholder="Correo Electronico" value={Mailenvio.direccion} onChange={cambiardirecion}/>
          </div>
          <div className="contenedorinput">
            <span className="Subtitulo">Tiempo de vida:</span>
            <input type="number" className="inputitem2" placeholder="tiempo en horas" value={Mailenvio.tiempo} onChange={cambiartiempo}/>
            <span className="Subtitulo">Horas</span>
          </div>
          <div className="contenedorinput">
            <button disabled={Mailenvio.enviando} className="Boton" onClick={shareMail}>enviar</button>
          </div>
        </div>
        <div className="Lista">
          <ul>
            
          </ul>
        </div>
      </div>
    );
}

const QR = (props)=>{
    if(props.paciente.ID===undefined){
        return(true)
    }
    let url = props.paciente.ShareQR()
    return (
      <div className="contenedor">
        <div className="inputdata">
          <div className="contenedorinput QR">
            <QRCode value={url} size={256}/>
          </div>
        </div>
        <div className="Lista">
          <ul>
            
          </ul>
        </div>
      </div>
    );
}

export default Share