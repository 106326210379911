import React, { useEffect, useState } from 'react'

import DownloadButton from "./DownloadButton/DownloadButton"
import Copy from "./Copy/DownloadButton"
import Axios from 'axios'

function EstudiosBox(props) {
    console.log("EstudiosBox",props)
    let onItemClick = (e)=>{
        props.SeleccionarEstudio(e)
    }
    let Items = []
    for (let i = 0; i < props.estudio.SERIES.length; i++) {
        Items.push(<Item serie={props.estudio.SERIES[i]} key={i} onClick={onItemClick} activo={props.seleccionado===i} index={i}/>)
    }
    //console.log("INFFFF"+props.VerInforme.existe)
    return (
        <div className="EstudiosBox">
        <DownloadButton/>
        <Copy/>
        {props.VerInforme.existe&&<InformeItem onClick={props.ShowInf}/>}
            {Items}
        </div>
    )
}

let Item = (props)=>{
    const [imagen, setimagen] = useState(null)
    let Click = ()=>{
        props.onClick(props.index)
    }
    useEffect(() => {
        let server = JSON.parse(window.localStorage.getItem("ConfiguracionGeneral")).ServidorDicom
        let file = props.serie.FILES[0]
        Axios.get(`${server}/thumbnail/${file.INS_UID}`,{
            responseType: 'blob'
        }).then((res)=>{
            setimagen(URL.createObjectURL(res.data))
        })
      return () => {
      }
    }, [])
    return(
        <>
        <img src={imagen} alt="icono" className="thumbnail" onClick={Click}/>
        <div className={`Item ${props.activo?"activo":""}`}  onClick={Click}>
            {props.serie.NOMBRE}
        </div>
        </>
    )
}

let InformeItem = (props)=>{
    return(
        <div className="InformeItem" onClick={props.onClick}>
            Informe
        </div>
    )
}
export default EstudiosBox
